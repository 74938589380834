import { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import StaticBreadCrumb from '../shared/breadcumb/StaticBreadCrumb';
import Button, { ButtonType } from '../shared/form-control/Button';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { ModuleBuilderEditorProps } from './ModuleBuilderTypes';
import TemplateFormService from '../../services/TemplateFormService';
import { FormConfig } from '../../models/Form';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { InputStyle } from '../shared/form-control/Input';
import DataRow from '../shared/data-grid/DataRow';
import { ModuleTemplate, ModuleTemplateForm, ModuleType } from '../../models/Module';
import TemplateModuleService from '../../services/TemplateModuleService';
import { InputSuggestion } from '../shared/form-control/InputSuggestions';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormType, formTypeOptions } from '../../models/FormTypes';
import Loader from '../shared/Loader';
import DateUtils from '../../utils/DateUtils';
import StatusTag, { StatusVariant } from '../shared/tags/StatusTag';
import { SortArray, SortingKey, SortingOptions } from '../../utils/ListUtils';
import { ChevronIcon, ChevronType } from '../shared/icon/ChevronIcon';
import { SelectListMenu } from '../shared/SelectListMenu';
import { Option } from '../Option';
import PageLoader from '../shared/page-loader/PageLoader';
import { SearchInput } from '../shared/form-control/SearchInput';
import throttle from 'lodash.throttle';
import TopNavPortal from '../layout/top-menu/TopNavPortal';
import { useRecoilValue } from 'recoil';
import { currentClientAtom, currentTenantIdAtom } from '../../recoil/atoms/Clients';
import ClientTemplateFormService from '../../services/ClientTemplateFormService';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';

const ModuleFormsEditor: FC<ModuleBuilderEditorProps> = (props) => {
  const currentClient = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const { module, patchModule, changesPending, saveTemplate, refetch } = props;

  const { sectionId } = useParams<{ sectionId: string | undefined }>();

  const [formTemplates, setFormTemplates] = useState<FormConfig[]>([]);
  const [moduleForms, setModuleForms] = useState<ModuleTemplateForm[]>([]);
  const [loadingForms, setLoadingForms] = useState(true);

  const [showChooseFormModal, setShowChooseFormModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState<FormConfig | null>(null);

  const [addFormSearch, setAddFormSearch] = useState('');
  const [search, setSearch] = useState('');

  const [formToMove, setFormToMove] = useState<ModuleTemplateForm | null>(null);
  const [showMoveFormModal, setShowMoveFormModal] = useState(false);
  const [moveFormToId, setMoveFormToId] = useState('');

  const [dropdownOpen, setDropdownOpen] = useState('');
  const [appliedFilters, setAppliedFilters] = useState<Record<string, number | undefined>>({});
  const [sorting, setSorting] = useState<SortingKey<ModuleTemplateForm> | null>(null);

  const { t } = useTranslation(['module-builder', 'organisation', 'common']);

  const sortingOptions = useMemo(() => SortingOptions(t), [t]);

  const applyFilter = (key: string, value: number) => {
    setAppliedFilters((filters) => ({ ...filters, [key]: value === -1 ? undefined : value }));
    setDropdownOpen('');
  };

  const applySorting = (key: SortingKey<ModuleTemplateForm>['key'], direction: number) => {
    setSorting(direction ? { key, direction } : null);
    setDropdownOpen('');
  };

  const location = useLocation();

  const section = useMemo(() => {
    return module.sections.find((x) => x.id === sectionId);
  }, [module.sections, sectionId]);

  const breadCrumbs = useMemo(() => {
    return [
      {
        name: t('organisation:tabs.modules'),
        path: currentClient ? `/clients/${currentClient.id}/organisation#modules` : `/builder/${currentTenantId}/#module-builder`,
      },
      {
        name: LanguageUtils.getTranslation('name', module.translations),
        hide: module.type === ModuleType.Document,
        path: currentClient
          ? `/clients/${currentClient.id}/module-builder/${module.id}/sections`
          : `/builder/${currentTenantId}/module-builder/${module.id}/sections`,
      },
      {
        name: LanguageUtils.getTranslation('name', section?.translations ?? {}),
        path: location.pathname,
        hide: module.type === ModuleType.Document,
      },
    ].filter((x) => !x.hide);
  }, [currentClient, currentTenantId, location.pathname, module.id, module.translations, module.type, section?.translations, t]);

  const templateFromService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateFormService(currentClient.id);
    }
    return TemplateFormService;
  }, [currentClient]);

  const templateModuleService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateModuleService(currentClient.id);
    }
    return TemplateModuleService;
  }, [currentClient]);

  const throttledFetchTemplates = useMemo(
    () =>
      throttle((searchTerm: string) => {
        if (!searchTerm) {
          setFormTemplates([]);
          return;
        }

        templateFromService
          .getAllTemplates({
            latestOnly: true,
            includeArchived: false,
            types: module.type === ModuleType.Default ? [FormType.Startup, FormType.Asset, FormType.Default] : [FormType.Document],
            searchTerm: searchTerm,
          })
          .then((res) => {
            setFormTemplates(res.data);
          });
      }, 1000),
    [module.type, templateFromService],
  );

  useEffect(() => {
    throttledFetchTemplates(addFormSearch);
  }, [addFormSearch, throttledFetchTemplates]);

  useEffect(() => {
    setLoadingForms(true);

    templateModuleService.getTemplateForms(module.id, section?.id).then((res) => {
      setLoadingForms(false);
      setModuleForms(res.data);
    });
  }, [module.id, section?.id, templateModuleService]);

  const formOptions = useMemo(() => {
    return formTemplates
      .filter((x) => !section?.forms.find((f) => f.templateFormId === x.id) && !x.archivedUtc)
      .map((x) => ({
        id: x.id,
        text: `${LanguageUtils.getTranslation('title', x.translations)} ${x.code?.length > 0 ? `(${x.code})` : ``} - v${x.version} ${
          x.isAssociated ? `(${LanguageUtils.getTranslation('associatedSectionName', x.associatedSectionTranslations)})` : ``
        }`,
        value: x.version,
        disabled: x.isAssociated,
      }));
  }, [formTemplates, section?.forms]);

  const addFormAndPatchModule = (formId: string, formVersion: number, type: (typeof FormType)[keyof typeof FormType]) => {
    patchModule({
      sections: module.sections.map((section) =>
        section.id === sectionId
          ? { ...section, forms: [...(section.forms || []), { templateFormId: formId, templateFormVersion: formVersion, templateFormType: type }] }
          : section,
      ),
    });
  };

  const addExistingForm = () => {
    if (!selectedForm) {
      return;
    }

    setModuleForms((prev) => [...prev, { ...selectedForm, sectionId: sectionId as string }]);
    addFormAndPatchModule(selectedForm.id, selectedForm.version, selectedForm.type);
    setShowChooseFormModal(false);
    setSelectedForm(null);
    setAddFormSearch('');
  };

  const removeForm = (formId: string) => {
    patchModule({
      sections: module.sections.map((sections) =>
        sections.id === section?.id ? { ...sections, forms: sections.forms.filter((y) => y.templateFormId !== formId) } : sections,
      ),
    });
  };

  const [templateModules, setTemplateModules] = useState<ModuleTemplate[]>([]);
  const [selectedTemplateModuleId, setSelectedTemplateModuleId] = useState(module.id);

  const moveForm = (form: ModuleTemplateForm) => {
    setSelectedTemplateModuleId(module.id);
    templateModuleService.getTemplates(undefined, undefined, [module?.type || ModuleType.Default]).then((res) => {
      let templates = res.data;
      if (currentClient?.id) {
        templates = templates.filter((x) => x.isOwnedByClient);
      }

      setTemplateModules(templates);
    });
    setFormToMove(form);
    setShowMoveFormModal(true);
  };

  const doMoveForm = () => {
    if (!formToMove) {
      return;
    }

    if (selectedTemplateModuleId !== module.id) {
      // move to a different module
      const selectedModule = templateModules.find((x) => x.id === selectedTemplateModuleId);
      if (selectedModule) {
        // move to new module
        templateModuleService
          .moveTemplateForm(module.id, {
            templateModuleId: module.id,
            templateModuleSectionId: sectionId || '',
            newTemplateModuleId: selectedModule.id,
            newTemplateModuleSectionId: moveFormToId,
            templateFormId: formToMove.id,
          })
          .then(() => refetch());
      }
    } else {
      // move to section in current module
      templateModuleService
        .moveTemplateForm(module.id, {
          templateModuleId: module.id,
          templateModuleSectionId: sectionId || '',
          newTemplateModuleId: module.id,
          newTemplateModuleSectionId: moveFormToId,
          templateFormId: formToMove.id,
        })
        .then(() => refetch());
    }

    onMoveFormClose();
  };

  const onMoveFormClose = () => {
    setShowMoveFormModal(false);
    setMoveFormToId('');
  };

  const moduleNames = useMemo(() => {
    return templateModules.map((x) => ({
      id: x.id,
      text: LanguageUtils.getTranslation('name', x.translations),
      value: LanguageUtils.getTranslation('name', x.translations),
    }));
  }, [templateModules]);

  const sectionNames = useMemo(() => {
    const selectedModule = templateModules.find((x) => x.id === selectedTemplateModuleId);
    return selectedModule
      ? selectedModule.sections
          .filter((x) => x.id !== sectionId)
          .map((x) => ({
            id: x.id,
            text: LanguageUtils.getTranslation('name', x.translations),
            value: LanguageUtils.getTranslation('name', x.translations),
          }))
      : [];
  }, [sectionId, selectedTemplateModuleId, templateModules]);

  const typeOptions = useMemo(() => formTypeOptions(t), [t]);
  const getFormType = useCallback(
    (formType: (typeof FormType)[keyof typeof FormType]) => {
      return typeOptions.find((typeOption) => typeOption.value === formType)?.text || '';
    },
    [typeOptions],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const statusOptions: Option<string, any>[] = useMemo(() => {
    return [
      {
        id: 'active',
        value: false,
        text: t('organisation:activities.filter.active'),
      },
      {
        id: 'archived',
        value: true,
        text: t('organisation:activities.filter.archived'),
      },
      {
        id: 'clear',
        value: -1,
        text: t('organisation:activities.filter.clear'),
      },
    ];
  }, [t]);

  const formsList = useMemo(() => {
    let result = section?.forms.map((formInfo) => moduleForms.find((template) => template.id === formInfo.templateFormId)).filter(Boolean);
    if (!result) {
      return result;
    }

    // Filtering
    for (const [key, filterValue] of Object.entries(appliedFilters)) {
      if (filterValue === undefined) {
        continue;
      }

      result = result.filter((row) => {
        if (!row) {
          return true;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let rowValue = (row as Record<string, any>)[key];

        if (typeof filterValue === 'boolean') {
          return filterValue === !!rowValue;
        }

        if (typeof rowValue === 'boolean') {
          rowValue = rowValue ? 1 : 0;
        }

        return rowValue === filterValue;
      });
    }

    // Searching
    const searchValue = search.toLowerCase();
    result = result.filter(
      (form) => !searchValue || (LanguageUtils.getTranslation('title', form.translations).toLowerCase().indexOf(searchValue) || 0) > -1,
    );

    // Sorting
    if (sorting) {
      result = SortArray(result, sorting);
    }

    return result;
  }, [section?.forms, search, sorting, moduleForms, appliedFilters]);

  return (
    <div className="bg-background-1 flex h-full flex-grow flex-col" data-cy="module-builder-forms-editor">
      <TopNavPortal>
        <StaticBreadCrumb
          breadCrumbs={breadCrumbs}
          currentStepName={
            module.type === ModuleType.Default ? t('module-builder:form-templates.heading') : t('module-builder:document-templates.heading')
          }
        />
      </TopNavPortal>
      <div className="page-content flex flex-grow flex-col overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between">
            <Heading size={HeadingSize.H3}>
              {module.type === ModuleType.Default ? t('module-builder:form-templates.heading') : t('module-builder:document-templates.heading')}
            </Heading>
            <div className="flex justify-between gap-4">
              <div className="w-80">
                <SearchInput
                  data-cy="search-template-forms"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={t('common:list.filter.search')}
                />
              </div>
              <Button data-cy="add-template-form" onClick={() => setShowChooseFormModal(true)} type={ButtonType.PRIMARY}>
                {t('module-builder:form-templates.buttons.add')}
              </Button>
              <Button data-cy="save-module" disabled={!changesPending} onClick={saveTemplate}>
                {t('module-builder:form-templates.buttons.save')}
              </Button>
            </div>
          </div>
        </div>
        <div className="p-6 pt-0">
          <PageLoader loading={loadingForms}>
            <div className="flex p-4 pr-0 pt-6">
              <div className="flex w-1/3" data-cy="list-header">
                <div className="w-1/3" data-cy="code">
                  {sorting?.key === 'code' && <div className="text-dpm-12">{t('organisation:activities.list.heading.code')}</div>}
                  <span className="cursor-pointer underline" onClick={() => setDropdownOpen('code')}>
                    {sorting?.key !== 'code'
                      ? t('organisation:activities.list.heading.code')
                      : sorting?.direction === -1
                        ? 'Descending'
                        : 'Ascending'}
                    {sorting?.key === 'code' && (
                      <ChevronIcon type={sorting.direction === -1 ? ChevronType.DOWN : ChevronType.UP} className="h-4 w-4" />
                    )}
                  </span>
                  <SelectListMenu
                    onBlur={() => setDropdownOpen('')}
                    isOpen={dropdownOpen === 'code'}
                    options={sortingOptions}
                    onClick={(o) => applySorting('code', o.value as number)}
                  >
                    {(triggerProps) => <div {...triggerProps} className="relative w-20"></div>}
                  </SelectListMenu>
                </div>
                <div className="w-2/3" data-cy="title">
                  {sorting?.key === 'title' && <div className="text-dpm-12">{t('organisation:activities.list.heading.name')}</div>}
                  <span className="cursor-pointer underline" onClick={() => setDropdownOpen('title')}>
                    {sorting?.key !== 'title'
                      ? t('organisation:activities.list.heading.name')
                      : sorting?.direction === -1
                        ? 'Descending'
                        : 'Ascending'}
                    {sorting?.key === 'title' && (
                      <ChevronIcon type={sorting.direction === -1 ? ChevronType.DOWN : ChevronType.UP} className="h-4 w-4" />
                    )}
                  </span>
                  <SelectListMenu
                    onBlur={() => setDropdownOpen('')}
                    isOpen={dropdownOpen === 'title'}
                    options={sortingOptions}
                    onClick={(o) => applySorting('title', o.value as number)}
                  >
                    {(triggerProps) => <div {...triggerProps} className="relative w-20"></div>}
                  </SelectListMenu>
                </div>
              </div>
              <div className="flex w-2/3" data-cy="type">
                <div className="w-1/4">
                  {appliedFilters.type !== undefined && <div className="text-dpm-12">{t('organisation:activities.list.heading.name')}</div>}
                  <span className="cursor-pointer underline" onClick={() => setDropdownOpen('filterType')}>
                    {appliedFilters.type === undefined
                      ? t('organisation:activities.list.heading.type')
                      : typeOptions.find((x) => x.value === appliedFilters.type)?.text}
                    <ChevronIcon type={ChevronType.DOWN} className="h-4 w-4" />
                  </span>
                  <SelectListMenu
                    onBlur={() => setDropdownOpen('')}
                    isOpen={dropdownOpen === 'filterType'}
                    options={typeOptions}
                    onClick={(o) => applyFilter('type', o.value as number)}
                  >
                    {(triggerProps) => <div {...triggerProps} className="relative w-20"></div>}
                  </SelectListMenu>
                </div>
                <div className="w-1/4" data-cy="version">
                  {' '}
                  {sorting?.key === 'version' && <div className="text-dpm-12">{t('organisation:activities.list.heading.version')}</div>}
                  <span className="cursor-pointer underline" onClick={() => setDropdownOpen('version')}>
                    {sorting?.key !== 'version'
                      ? t('organisation:activities.list.heading.version')
                      : sorting?.direction === -1
                        ? 'Descending'
                        : 'Ascending'}
                    {sorting?.key === 'version' && (
                      <ChevronIcon type={sorting.direction === -1 ? ChevronType.DOWN : ChevronType.UP} className="h-4 w-4" />
                    )}
                  </span>
                  <SelectListMenu
                    onBlur={() => setDropdownOpen('')}
                    isOpen={dropdownOpen === 'version'}
                    options={sortingOptions}
                    onClick={(o) => applySorting('version', o.value as number)}
                  >
                    {(triggerProps) => <div {...triggerProps} className="relative w-20"></div>}
                  </SelectListMenu>
                </div>
                <div className="w-1/4" data-cy="modified-utc">
                  {sorting?.key === 'modifiedUtc' && <div className="text-dpm-12">{t('organisation:activities.list.heading.modified')}</div>}
                  <span className="cursor-pointer underline" onClick={() => setDropdownOpen('modifiedUtc')}>
                    {sorting?.key !== 'modifiedUtc'
                      ? t('organisation:activities.list.heading.modified')
                      : sorting?.direction === -1
                        ? 'Descending'
                        : 'Ascending'}
                    {sorting?.key === 'modifiedUtc' && (
                      <ChevronIcon type={sorting.direction === -1 ? ChevronType.DOWN : ChevronType.UP} className="h-4 w-4" />
                    )}
                  </span>
                  <SelectListMenu
                    onBlur={() => setDropdownOpen('')}
                    isOpen={dropdownOpen === 'modifiedUtc'}
                    options={sortingOptions}
                    onClick={(o) => applySorting('modifiedUtc', o.value as number)}
                  >
                    {(triggerProps) => <div {...triggerProps} className="relative w-20"></div>}
                  </SelectListMenu>
                </div>
                <div className="w-1/4" data-cy="status">
                  {appliedFilters.archivedUtc !== undefined && <div className="text-dpm-12">{t('organisation:activities.list.heading.status')}</div>}
                  <span className="cursor-pointer underline" onClick={() => setDropdownOpen('status')}>
                    {appliedFilters.archivedUtc === undefined
                      ? t('organisation:activities.list.heading.status')
                      : statusOptions.find((x) => x.value === appliedFilters.archivedUtc)?.text}
                    <ChevronIcon type={ChevronType.DOWN} className="h-4 w-4" />
                  </span>
                  <SelectListMenu
                    onBlur={() => setDropdownOpen('')}
                    isOpen={dropdownOpen === 'status'}
                    options={statusOptions}
                    onClick={(o) => applyFilter('archivedUtc', o.value as number)}
                  >
                    {(triggerProps) => <div {...triggerProps} className="relative w-20"></div>}
                  </SelectListMenu>
                </div>
              </div>

              <div className="mr-2 w-8">{/* SPACER */}</div>
              <div className="w-10">{/* SPACER */}</div>
            </div>

            {formsList?.map((form, i) => {
              return (
                <DataRow
                  data-cy={`form-template-${i}`}
                  key={form?.id || new Date().getTime() * Math.random()}
                  url={
                    form
                      ? currentClient
                        ? `/clients/${currentClient.id}/form-builder/${form.id}/${form.version}`
                        : `/builder/${currentTenantId}/form-builder/${form.id}/${form.version}`
                      : undefined
                  }
                  contextMenuItems={[
                    { title: t('module-builder:form-templates.menu.move'), onClick: () => form && moveForm(form), hide: !form },
                    { title: t('module-builder:form-templates.menu.remove'), onClick: () => removeForm(form?.id || ''), hide: !form },
                  ]}
                >
                  {!form && (
                    <div className="relative h-full min-h-6 w-full">
                      <Loader size={10} />
                    </div>
                  )}
                  {form && (
                    <>
                      <div className="flex w-1/3 items-center">
                        <div className="w-1/3" data-cy="code">
                          {form.code}
                        </div>
                        <div className="w-1/2" data-cy="title">
                          {LanguageUtils.getTranslation('title', form.translations)}
                        </div>
                      </div>
                      <div className="flex w-2/3">
                        <div className="w-1/4" data-cy="type">
                          {getFormType(form.type)}
                        </div>
                        <div className="w-1/4" data-cy="version">
                          {form.version}
                        </div>
                        <div className="w-1/4" data-cy="modified-utc">
                          {DateUtils.formatDate(new Date(form.modifiedUtc))}
                        </div>
                        <div className="flex w-1/4 justify-start" data-cy="status">
                          {form.archivedUtc ? (
                            <StatusTag text={t('organisation:activities.filter.archived')} statusType={StatusVariant.RED} />
                          ) : (
                            <StatusTag text={t('organisation:activities.filter.active')} statusType={StatusVariant.GREEN} />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </DataRow>
              );
            })}
          </PageLoader>
        </div>
      </div>

      <ModalContext.Provider
        value={{
          open: showChooseFormModal,
          onClose: () => {
            setAddFormSearch('');
            setShowChooseFormModal(false);
          },
          modalWidth: 'w-2/5',
        }}
      >
        <StandardModal
          title={t('module-builder:form-templates.modals.create.title')}
          cancelButtonTitle={t('module-builder:form-templates.modals.create.buttons.cancel')}
          confirmButtonTitle={t('module-builder:form-templates.modals.create.buttons.create')}
          onCancelClick={() => {
            setAddFormSearch('');
            setShowChooseFormModal(false);
          }}
          onConfirmClick={addExistingForm}
          confirmDisabled={!selectedForm}
        >
          <InputSuggestion
            data-cy="form-search"
            suggestions={formOptions}
            inputConfig={{
              value: addFormSearch,
              placeholder: t('common:list.filter.search'),
              style: InputStyle.NORMAL,
            }}
            onChange={(value) => {
              setAddFormSearch(value);
            }}
            onPick={(option) => {
              setSelectedForm(formTemplates.find((x) => x.id === option.id) || null);
              setAddFormSearch(option.text);
            }}
            noMatchMessage={addFormSearch ? t('module-builder:form-templates.modals.create.no-forms') : ''}
          />
        </StandardModal>
      </ModalContext.Provider>

      <ModalContext.Provider value={{ open: showMoveFormModal, onClose: onMoveFormClose, modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('module-builder:form-templates.modals.move.title', {
            title: LanguageUtils.getTranslation('title', formToMove?.translations ?? {}),
          })}
          cancelButtonTitle={t('module-builder:form-templates.modals.move.buttons.cancel')}
          confirmButtonTitle={t('module-builder:form-templates.modals.move.buttons.move')}
          onCancelClick={onMoveFormClose}
          onConfirmClick={doMoveForm}
          confirmDisabled={!moveFormToId}
        >
          <div className="text-dpm-12">{t('module-builder:form-templates.modals.move.module')}</div>
          <DropdownSelect
            options={moduleNames}
            value={moduleNames.find((x) => x.id === selectedTemplateModuleId)}
            onChange={(o) => setSelectedTemplateModuleId(o.id)}
            placeholder={t('module-builder:form-templates.modals.move.module')}
            data-cy="module-select"
          />
          <div className="text-dpm-12 mt-4">{t('module-builder:form-templates.modals.move.section')}</div>
          <DropdownSelect
            options={sectionNames}
            value={sectionNames.find((x) => x.id === moveFormToId)}
            onChange={(o) => setMoveFormToId(o.id)}
            placeholder={t('module-builder:form-templates.modals.move.section')}
            data-cy="section-select"
          />
        </StandardModal>
      </ModalContext.Provider>
    </div>
  );
};

export default ModuleFormsEditor;
